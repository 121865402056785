/* You can add global styles to this file, and also import other style files */
// @import "bootstrap/bootstrap";
// @import "bootstrap_old/bootstrap";
@import "spinner";
@import "utils";
@import "rtl";
@import "responsive";

.dropdown-item.active a {
    color: white !important;
}

body {
    background-color: #f9f9f9;
}
.jumbotron {
    background-color: #BEBEBE;
}

.row {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
}

.card{
    margin-bottom: 15px;
}
google-chart > div> table {
    margin: 0px auto 0px auto !important;
}
.hide-sm{
    display:block;
}
.hide-inline-sm{
    display: inline-block;
}
.show-sm{
    display:none;
}
.show-inline-sm{
    display:none;
}
.tab-content{
    overflow: auto; 
    height: 100%;
}
.btn {
    padding: 0.125rem 0.5rem; /* 1.25rem top and bottom, 0.5rem left and right */
}
.text-justify {
    text-align: justify;
    hyphens: auto;
}

/* Ensure horizontal scrollbar is always visible */
.ag-body-horizontal-scroll {
    opacity: 1 !important;
    visibility: visible !important;
    height: 16px !important;
    min-height: 16px !important;
    max-height: 16px !important;
}

/* Override AG-Grid's auto-hide behavior */
.ag-scrollbar-invisible {
    opacity: 1 !important;
    visibility: visible !important;
}

/* Force scrollbar styling */
.ag-body-horizontal-scroll-viewport {
    overflow-x: scroll !important;
}

.ag-body-horizontal-scroll-container {
    height: 16px !important;
}

/* WebKit (Chrome, Safari) - Customize scrollbar */
.ag-body-horizontal-scroll ::-webkit-scrollbar {
    height: 16px !important;
}

.ag-body-horizontal-scroll ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5); /* Darker scrollbar for visibility */
    border-radius: 8px;
}

.ag-body-horizontal-scroll ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
}

.ag-body-viewport {
    padding-bottom: 20px; /* Adjust this based on scrollbar height */
}


@media screen and (max-width: 768px) {

    .hide-sm{
        display:none;
    }
    .hide-inline-sm{
        display:none;
    }
    .show-sm{
        display:block;
    }
    .show-inline-sm{
        display: inline-block;
    }

}
