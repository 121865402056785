.spinner-cover {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.3);
    z-index:9999;
    cursor: pointer;
    display: block;
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%,-50%); /* IE 9 */
    -webkit-transform: translate(-50%,-50%); /* Safari */
    transform: translate(-50%,-50%); /* Standard syntax */
    width: 60px;
    height: 60px;
    z-index:9998;
}
.spinner.hidden {
    display: none;
}
.spinner-cover.hidden  {
    display: none;
}
.double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #000;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
    0%, 100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    } 50% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}
